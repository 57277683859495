footer {
    width: 100%;
    background-color: #fafafb;
    bottom: 0;
    display: flex;
    justify-content: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #1E212F;
}

footer .container {
    width: 100%;
    max-width: 950px;
    height: 100%;
    list-style: none;
    padding: 40px 0;
    margin: 0;
}
footer .container .main {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e7e7e9;
    padding-bottom: 40px;
}
footer .container .links-container {
    display: flex;
    justify-content: space-between;
}
footer .container .links-container .links-column {
    display: flex;
    flex-direction: column;
    width: 140px;
    margin-left: 40px;
}
footer .container .links-container .links-column:nth-child(1) {
    margin-left: 0px;
}
footer .container .links-container .links-column.socials {
    width: 80px;
}
footer .container .links-container .links-heading {
    font-weight: 600;
    padding-bottom: 5px;
}
footer .container .links-container a {
   text-decoration: none;
   color: #1E212F;
   padding: 5px 0;
}
footer #logo {
    fill: #2D4EE9;
}
footer .summary {
    padding-bottom: 30px;
}
footer .summary > div {
    width: 250px;
}

footer .container .lower {
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

footer #products-heart {
    height: 12px;
    position: relative;
    top: 1px;
    left: 2px;
}
footer .to_top button {
    float: right;
    font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    height: 45px;
    width: 45px;
    padding: 0;
    border: none;
    font-size: 0.8em;
    font-weight: 600;
    border-radius: 5px;
    background: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-bottom: 20px;
}
footer .to_top button svg {
    transform: rotate(180deg);
}

@media (min-width: 1200px) {
    footer .container {
      max-width: 85%;
    }
 }
 @media (max-width: 1000px) {
    footer .container {
        margin: 0 20px;
    }
 }
 @media (max-width: 1200px) {
    footer .container .main {
        flex-direction: column;
    }
 }
 @media (max-width: 900px) {
    footer .container .links-container .links-column:nth-child(n+5) {
        display: none;
    }
 }

 @media (max-width: 650px) {
    footer .container .links-container .links-column:nth-child(n+4) {
        display: none;
    }
 }
 @media (max-width: 475px) {
    footer .container .links-container {
        flex-direction: column;
    }
    footer .container .links-container .links-column {
        margin-left: 0;
        padding: 20px 0;
    }
 }


