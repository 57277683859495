.about {
    background-color: #FFF;
    color: #222;
}


.about .width-constraint h1 {
    font-size: 4em;
    font-weight: 900;
    color: #333;
    letter-spacing: -3px;
    text-align: left;
}

.about .width-constraint p {
    font-size: 3em;
    font-weight: 600;
    color: #333;
    letter-spacing: -3px;
    text-align: left;
    width: 100%;
}

.about .width-constraint a {
    color: #2D4EE9;
    outline: none;
    text-decoration: none;
    border-bottom: 3px solid #2D4EE9;
}

.about .component-wrap {
    position: relative;
    display: flex;
    justify-content: center;
  }
 
  .about .width-constraint h1 {
    font-size: 4em;
    font-weight: 900;
    letter-spacing: -3px;
    text-align: left;
    -webkit-text-stroke: 1px #FFF;
    overflow: visible;
    margin-block-start: 0em;
    margin-block-end: 0em;
}
  
.about .width-constraint {
    position: relative;
    max-width: 950px;
    width: 100%;
    height: 100%;
    padding-top: 100px;
    padding-bottom: 100px;
    display: flex;
    justify-content: flex-start !important;
    align-items: center;
  }


@media (min-width: 750px) {
    #nav_bg {
        height: 90px;
    }
}

@media (max-width: 750px) {
  .about .component-wrap p {
      position: relative;
      margin: auto;
      bottom: 0;
      margin-bottom: 50px;
  }
  .about .width-constraint {
    font-size: 0.5em;
    padding-bottom: 0px;
    max-width: 100%;
  }
  .about .width-constraint p {
      letter-spacing: -1px;
  }
}

@media (max-width: 950px) {
    .width-constraint {
        margin: 0;
    }
}

@media (min-width: 1200px) {
    .about .width-constraint {
        max-width: 85%;
      }
}