.side_info {
    position: absolute;
    height: 300px;
    width: 0px;
    bottom: 0;
    top: 0;
    right: 30px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    opacity: 1;
    font-weight: 400;
    transition-duration: 0.5s;
    transition-delay: 0.5s;
}
.side_info.hidden {
    right: -50px;
    transition-duration: 0.15s;
    transition-delay: 0s;
    opacity: 0;
}

.side_info_list {
    height: 100%;
}

.side_info_list ul {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    margin: 0;
    padding: 0;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0px;
}

.side_info_list li {
    padding: 10px;
    list-style-type: none;
}

.side_info_list li a {
    font-size: 0.8em;
    font-weight: 400;
    color: rgb(200, 252, 245);
    
    text-decoration: none;
}

.bottom_info.hidden {
    bottom: -200px;
    transition-delay: 0ms;
    display: none;
}

.side_info a:focus {
    font-weight: 600;
    outline: none;
    color: #FFF;
}

@media (min-width: 1200px) {
    .side_info_component.left { left: 2%; }
    .side_info_component.right { right: 2%; }
}

@media (max-width: 750px) {
    .side_info {
        display: none;
    }
}