.menu_wrap {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 12;
    height: 0;
    width: 100%;
    transition: all 0.5s 0.3s;
}

.menu {
    position: absolute;
    top: 0;
    left: 0;
    height: 0vh;
    width: 100%;
    transition: height 0.5s 0.3s;
}
.menu_wrap.open{
    height: 100%;
    width: 100%;
    transition: all 0.5s 0s;
}

.menu.open {
    height: 100%;
    transition: height 0.5s 0s;
}

.menu_goo {
    z-index: 105;
    fill: #000;
    position: fixed;
    left: -10vw;
    bottom: 100vh;
    height: 0;
    width: 120vw;
    transition: bottom 1s 0.25s, height 1.5s 0.25s;
}

.menu_goo.open {
    bottom: -45vh;
    height: 160vh;
    transition: bottom 1s 0.25s, height 1.5s;
}
.menu-img {
    position: fixed;
    overflow: hidden;
    display: flex;
    justify-content: flex-end;
    right: 0px;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 500px;
    height: 500px;
    opacity: 0;
    visibility: hidden;
    transition-delay: 0s;
    transition-duration: 0s;
    z-index: 106;
}

.menu-img img {
    position: relative;
    right: -120px;
}
.menu_wrap.open .menu-img {
    opacity: 1;
    visibility: visible;
    transition-delay: 1s;
    transition-duration: .5s;
}

.menu-list {
    z-index: 110;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: hidden;
    transition-delay: 1s;
}
.menu.open .menu-list {
    visibility: visible;
    transition-delay: 0s;
}


.menu-list ul {
    position: relative;
    z-index: 200;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    list-style-type: disc;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0px;
    padding: 0;
    margin: 0;
    margin-left: 25px;
    margin-right: 25px;
    max-width: 1000px;
}
    
.menu-list ul li {
    list-style: none;
    text-align: left;
    width: 100%;
    margin: 2vh 0;
}

.menu-list ul li a {
    font-weight: 900;
    padding: 0;
    font-size: 6em;
    color: #F4F4F4; 
    text-decoration: none;
    text-align: left;
    letter-spacing: 2px;
    opacity: 0;
}

.menu-list ul li:nth-child(1) a {
    transition: opacity 0.5s;
}
.menu-list ul li:nth-child(2) a {
    transition: opacity 0.4s;
}
.menu-list ul li:nth-child(3) a {
    transition: opacity 0.2s;
}
.menu-list ul li:nth-child(4) a {
    transition: opacity 0s;
}

.menu a:focus {
    outline: none;
    color: #777;
 }

.menu-list ul li a::after {
    font-weight: 300;
    font-size: 0.15em;
    position: relative;
    bottom: 5em;
    color: #777;
}
.menu a:focus::after {
    color: #2D4EE9;
 }

.menu-list ul li:nth-child(1) a::after {
    content: "01.";
    font-weight: 900;
}
.menu-list ul li:nth-child(2) a::after {
    content: "02.";
    font-weight: 900;
}
.menu-list ul li:nth-child(3) a::after {
    content: "03.";
    font-weight: 900;
}
.menu-list ul li:nth-child(4) a::after {
    content: "04.";
    font-weight: 900;
}
.menu-list ul li:nth-child(5) a::after {
    content: "05.";
    font-weight: 900;
}

.menu-list ul li a:hover {
    cursor: pointer;
}

.menu.open .menu-list ul li a {
    opacity: 1;
}
.menu.open .menu-list ul li:nth-child(1) a {
    transition: opacity 0.5s 0.5s;
}
.menu.open .menu-list ul li:nth-child(2) a {
    transition: opacity 0.5s 0.6s;
}
.menu.open .menu-list ul li:nth-child(3) a {
    transition: opacity 0.5s 0.7s;
}
.menu.open .menu-list ul li:nth-child(4) a {
    transition: opacity 0.5s 0.8s;
}


.socials-wrap {
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 50px;
    margin: auto;
    opacity: 0;
    max-width: 1000px;
    z-index: 110;
}

.menu.open .socials-wrap {
    opacity: 1;
    transition: opacity 0.5s 1s;
}

.socials-wrap ul {
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
    margin: 0;
    margin-left: 25px;
    margin-right: 25px;
}
.socials-wrap ul li {
    width: fit-content;
}
.socials-wrap a {
    font-weight: 600;
    font-size: 0.8em;
    color: #F4F4F4;
    text-decoration: none;
    padding: 10px;
    margin: 0;
    cursor: pointer;
}

.socials-wrap #email {
    font-weight: 600;
}

@media (max-width: 750px) {
    .menu-list ul li a {
        font-size: 4em;
        position: relative;
        bottom: 50px;
    }
    .menu-img {
        bottom: -350px;
    }
}
@media (max-width: 500px) {
    .menu-list ul li a {
        font-size: 3em;
    }
}

@media (min-width: 1000px) {
    .socials-wrap ul {
        margin-left: 0px;
        margin-right: 0px;
    }
    
}
@media (min-width: 1200px) {

    .menu-list ul {
        max-width: 85vw;
        margin: 0;
    }
    .socials-wrap {
        max-width: 85vw;
    }
}

@media (min-width: 1500px) {
    .menu-list ul li a {
        font-size: 7em;
    }
}

/* Deleted styles */
/* 
.subscribe-wrap {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.subscribe-wrap {
    position: relative;
    right: 20px;
}

.subscribe-wrap label {
    font-weight: 600;
    font-size: 0.8em;
    color: #F4F4F4;
    cursor: pointer;
}

.subscribe-wrap svg {
    position: absolute;
    right: 5px;
    bottom: 12px;
    stroke: #F4F4F4;
}

.menu-bottom ul li input {
    padding: 5px;
    position: relative;
    bottom: 5px;
    padding-left: 0;
    font-size: 0.8em;
    width: 135px;
    background: transparent;
    color: #F4F4F4;
    font-weight: 600;
    outline: none;
    border: none;
    border-bottom: 1px solid #F4F4F4;
    cursor: pointer;
}
.menu-bottom ul li input:focus {
    border-bottom: 3px solid #FF5E0D;
}

.menu-bottom ul li input::placeholder {
    color: #111;
}

.menu-bottom {
    position: absolute;
    bottom: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
}

.menu-bottom ul {
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    max-width: 1000px;
}

.menu.open .menu-bottom {
    opacity: 1;
    transition: opacity 0.5s 0.5s;
}

.menu-bottom ul li {
    margin: 0px;
    list-style: none;
    width: 300px;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.menu-bottom ul li:nth-child(1) {
    padding-left: 20px;
    justify-content: flex-start;
    align-items: flex-start;
}

.menu-bottom ul li:nth-child(2) {
    justify-content: flex-end;
    padding-right: 20px;
} */