html, body, #root, .cursor_wrap {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
}


body {
  background-color: #FFF;
  
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.text-transition {
  padding-right: 10px;
}

h1 {
  
  display: block;
}

.component-wrap {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  
}
.component-wrap h1  {
  margin-block-start: 0em;
  margin-block-end: 0em;
  overflow: hidden;
}

.width-constraint {
  position: relative;
  max-width: 950px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  
}


@media (max-width: 950px) {
  .width-constraint {
    margin: 20px;
  }
}

@media (min-width: 1200px) {
  .width-constraint {
    max-width: 85%;
  }
}

.cursor {
  position: absolute;
  pointer-events: none;
  z-index: 900;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  transition-duration: 150ms;
  transition-timing-function: ease-out;
  
}
.cursor.active {
  background-color: #1E212F;
  width: 50px;
  height: 50px;
  mix-blend-mode: exclusion;
  transition-duration: 200ms;
  transition-timing-function: ease-out;
}

.cursor_trail.active {
  width: 50px;
  height: 50px;
  border: 3px solid #2D4EE9;
  /* box-shadow: 0px 0px 20px #FF5E0D; */
}


.cursor_trail {
  pointer-events: none;
  z-index: 1000;
  width: 20px;
  height: 20px;
  border: 1px solid #2D4EE9;
  border-radius: 50%;
  position: absolute;
  transition-duration: 300ms;
  transition-timing-function: ease-out;
  /* animation: cursorAnim .5s infinite alternate; */
}

@keyframes cursorAnim {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(3);
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}