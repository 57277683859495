.contact {
    height: 100%;
    width: 100vw;
    background-color: #FFF;
    color: #1E212F;
    position: relative;
    cursor: default;
}


.contact .component-wrap {
    height: 100%;
    width: 100%;
}
.contact .width-constraint {
    transition: all 0.5s;
}

.headline {
    position: absolute;
    height: auto;
    top: 0;
    bottom: 0;
    margin: auto;
    height: fit-content;
    width: fit-content;
    width: 100%;
}

.contact .headline h1  {
    position: relative;
    opacity: 1;
    left: 0;
    font-size: 4em;
    color: #1E212F;
    font-weight: 900;
    letter-spacing: -0.05em;
    transition-duration: 0.5s;
    transition-delay: 0.5s;
}

/* .contact .headline h1 a:hover {
    color: #FF5E0D;
} */


.headline.hidden h1 { 
    left: -100px;
    transition-duration: 0.5s;
    transition-delay: 0s;
    opacity: 0;
}


.text_bottom {
    position: relative;
    bottom: 20px;
}


span.superscript > div {
    font-size: 0.25em;
    font-weight: 400;
    letter-spacing: 0px;
    transform: translateX(-50px) translateY(1em);
}
span.superscript > span {
    transform: translateX(-35px)
}
.contact .regular_text {
    font-size: 2em;
    line-height: 1.5em;
    font-weight: 600;
    text-align: center;
}

.contact .regular_text.form {
    display: flex;
    justify-content: space-evenly;
}

.contact .regular_text.form .react-reveal {
    margin-bottom: 5px;
}

.inline_text {
    display: inline-block;
}
.inline_link {
    display: inline-block;
    color: #2D4EE9;
    
}
.message_topic {
    display: inline-block;
}
.inline_dropdown {
    background: none;
    border: none;
    font-size: 1.05em;
    font-weight: 600;
    color: #3A5BFA;
    
}
.inline_input {
    background: none;
    display: inline;
    border: none;
    color: #3A5BFA;
    font-size: 1.05em;
    font-weight: 600;
    max-width: 250px;
}
.inline_input.name {
    margin: 0 !important;
}

.inline_input::placeholder {
    color: #3A5BFA;
}

.inline_input:focus {
    outline: none;
    border-bottom: 2px solid #3A5BFA;
}

.contact .inline_input {
    display: block;
    text-align: center;
    margin: auto;
    left: 0;
    right: 0;
}

ul.topic_dropdown {
    position: absolute;
    color: #1E212F;
    background-color: #FFF;
    margin: 0;
    left: 300px;
    display: flex;
    flex-direction: column;
    list-style: none;
    z-index: 10;
    left: 0;
    right: 0;
    margin: auto;
    width: fit-content;
    padding: 0;
    border-radius: 5px;
    box-shadow: 0 10px 50px rgba(0,0,0,0.075);
}
.topic_dropdown li {
    
    padding-left: 0.5em;
    padding-right: 0.5em;
    padding: 0.4em 0.5em;
    
}
.topic_dropdown li:hover {
    background-color: #FFF;
}

.topic_dropdown.hidden {
    display: none;
}

.topic_toggle {
    display: inline-block;
    color: #3A5BFA;
    font-size: 1.05em;
    border: none;
    font-weight: 600;
    background: none;
    cursor: pointer;
}

.topic_toggle:focus {
    color: #3A5BFA;
    outline: none;
}
.topic_toggle svg {
    stroke: #3A5BFA;
}
.topic_toggle:focus svg {
    stroke: #3A5BFA;
}

.inline_button {
    display: inline-block;
    color: #1E212F;
    font-size: 1.05em;
    border: none;
    font-weight: 600;
    background: none;
    text-decoration: underline;
}
.inline_button:hover {
    cursor: pointer;
}

.contact_form_btn {
    font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    position: relative;
    height: 40px;
    width: 140px;
    margin: 1.5em;
    padding: 0;
    border: none;
    color: #FFF;
    font-size: 13px;
    font-weight: 600;
    border-radius: 5px;
    background: #3A5BFA;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: all 0.5s;
}
.contact_form_btn:disabled,
.contact_form_btn:disabled:hover {
    background: #E5E5E5;
    color: #434755;
}
.contact_form_btn .text {
    width: 115px;
}

.contact_form_btn .icon {
    background: #2D4EE9;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.contact_form_btn .icon svg {
    fill: #FFF;
}
.contact_form_btn:disabled .icon,
.contact_form_btn:disabled:hover .icon {
    background: #d9d8d8;
    color: #1E212F;
}
.contact_form_btn:disabled .icon svg,
.contact_form_btn:disabled:hover .icon svg {
    fill: #434755;
}
.contact_form_btn:hover {
    cursor: pointer;
}

.big_link a {
    color: #2D4EE9;
}
.regular_text a:focus {
    outline: none;
    color: #1E212F;
}
.contact .headline h1 {
    text-align: center;
}

.contact .headline {
    opacity: 1;
    transition-duration: 0.5s;
    transition-delay: 0.5s;
}
.contact .headline.hidden {
    opacity: 0;
    bottom: -200px;
    transition-duration: 0.5s;
    transition-delay: 0s;
}

.contact .headline.hidden h1 { 
    left: 0;
}

.contact .form-bg {
    position: relative;
    bottom: 50px;
    background: #FFF;
    border-radius: 15px;
    height: 350px;
    width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    box-shadow: 0 10px 20px rgba(0,0,0,0.075);
    overflow: hidden;
}

.contact .title {
    background: #FFF;
    color: #1E212F;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    
}
.contact .title h1 { 
    color: #1F212F;
    margin: 10px;
    padding: 20px 0px;
    font-size: 2em;
    text-align: left;
}
.contact .title p { 
    color: #1F212F;
    margin: 10px;
    font-size: 0.7em;
    font-weight: 300;
    max-width: 370px;
    text-align: left;
    line-height: 1.2em;
}

.contact .form-bg .inputs {
    position: relative;
    top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.contact .form-bg .inputs .input-group {
    width: 310px;
    text-align: left;
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    pointer-events:none;
    
}
.contact .form-bg .inputs .input-group.small {
    height: 50px;
}

.contact .form-bg .inputs .input-group.large {
    height: 100px;

}
.contact .form-bg .inputs .input-group label {
    font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-size: 16px;
    color: #1E212F;
    position: relative;
    line-height: 10px;
    left: 5px;
    top: 35px;
    transition: all 0.5s;
}
.contact .form-bg .inputs .input-group label.selected {
    color: #2D4EE9;
    font-size: 12px;
    top: 5px;
    
}
.contact .form-bg .inputs .input-group input {
    margin: 0;
    width: 290px;
    font-size: 16px;
    margin: 10px 0;
    padding: 10px;
    color: #1E212F;
    text-align: left;
    border: none;
    background: #FFF;
    font-weight: 500;
    font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    border-bottom: 1px solid #E5E5E5;
    pointer-events: auto;
}
.contact .form-bg .inputs .input-group textarea {
    width: 290px;
    font-size: 16px;
    margin: 10px 0;
    padding: 10px;
    color: #1E212F;
    text-align: left;
    border: none;
    font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    border-radius: 0;
    background: #FFF;
    border-bottom: 1px solid #e4effa;
    pointer-events: auto;
}

.contact .form-bg .inputs .input-group input:focus, .contact .form-bg .inputs .input-group input:active,
.contact .form-bg .inputs .input-group textarea:focus, .contact .form-bg .inputs .input-group textarea:active {
    outline: none;
    background: #FFF;
    border-bottom: 1px solid #2D4EE9;
}
.contact .form-bg .submit {
    width: 350px;
    display: flex;
    justify-content: flex-start;
}



@keyframes scrollText {
    0% {opacity: 1;}
    30% {opacity: 0;}
    70% {opacity: 0;}
    100% {opacity: 1;}
}
@keyframes scrollImage {
    0% {
        opacity: 1;
        transform: translateY(0px);
     }
    50% {
          opacity: 0;
        transform: translateY(-200px);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}
@keyframes scrollImageUp {
    0% {
        opacity: 0;
        transform: translateY(0px);
     }
    50% {
          opacity: 0;
        transform: translateY(-200px);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}


.image.scroll {
    animation: scrollText 1.5s ease-in-out;
}

@media (max-width: 900px) {
    .contact .regular_text {
        font-size: 1.5em;
        line-height: 1.5em;
    }
    .contact .regular_text.form {
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
    .contact .title h1, .contact .title p { 
        text-align: center;
    }
    .contact .title p { 
        padding-bottom: 50px;
    }
}

@media (max-width: 500px) {
    .contact .form-bg {
        box-shadow: none;
        position: relative;
        top: 15px;
    }
    .contact .form-bg .submit {
        justify-content: center;
    }
    .contact .title {
        display: none;
    }
    .contact .regular_text {
        font-size: 1.25em;
        line-height: 1.25em;
    }
}