.projects {
    color: #333;
}

.projects .width-constraint h1 {
    font-size: 7em;
    font-weight: 900;
    color: #333;
    /* -webkit-text-stroke: 2px #000; */
    letter-spacing: -3px;
    text-align: left;
    overflow: visible;
    margin-block-start: 0em;
    margin-block-end: 0em;
    padding-bottom: 50px;
}

.loading.projects  {
    height: 100%;
    width: 100%;
    background-color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
}

.projects .width-constraint p {
    font-size: 3em;
    font-weight: 600;
    color: #000;
    letter-spacing: -3px;
    text-align: left;
}

.projects .component-wrap {
    position: relative;
    display: flex;
    justify-content: center;
}

.projects .width-constraint {
    position: relative;
    max-width: 950px;
    width: 100%;
    min-height: 100%;
    padding-top: 150px;
    display: flex;
    align-items: center;
    justify-content: flex-start !important;
}

.projects .react-reveal {
    padding-bottom: 50px;
}

.projects_grid {
    background-color: transparent;
    margin-block-start: 0em;
    margin-top: 0px;
    margin-block-end: 0em;
    padding-inline-start: 0px;
    width: 100%;
    max-width: 2000px;
    min-height: 100vh;
    height: auto;
    text-align: center;
}

.projects_grid li {
    list-style: none;
    width: 350px;
    height: 250px;
    vertical-align: top;
    display: inline-block;
    margin: 10px;
    border-radius: 10px;  
}

.projects_grid li:hover {
    cursor: pointer;
}

.projects_grid li > div {
    height: 250px;
    width: 350px;
    margin: 0px;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;  
}

.project_image_small {
    width: 100%;
    height: auto;
    border-radius: 10px;
}

.project_image_small_loading {
    background: rgb(216, 216, 216);
    width: 100%;
    height: 100%;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
}
.project_image_large { 
    width: calc(100% - 2em);
    border-radius: 10px;
}
.project_image_large_loading {
    background: rgb(216, 216, 216);
    width: calc(100% - 2em);
    height: 658px;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
}

.project_image_large_loading > div, .project_image_small_loading > div {
    background: rgb(234, 234, 234);
    height: 1000px;
    width: 100px;
    transform: rotate(30deg);
    top: -200px;
    left: -350px;
    position: absolute;
    animation: loadingSlide 0.75s ease-in-out infinite;
}

.project_image_large_loading > div:nth-child(2), .project_image_small_loading > div:nth-child(2) {
    animation: loadingSlide 0.75s ease-in-out 0.25s infinite;
}

@keyframes loadingSlide {
    from {left: -350px; opacity: 0.1;}
    to {left: 1000px; opacity: 0.2;}
  }


.projects_grid li .project_info {
    position: absolute;
    display: flex;
    height: 70px;
    bottom: -70px;
    width: 100%;
    transition: all 0.3s;
    background: rgb(0,0,0);
    background: -moz-linear-gradient(0deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0) 100%);
    background: -webkit-linear-gradient(0deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0) 100%);
    background: linear-gradient(0deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0) 100%);
    
}

.projects_grid li:hover .project_info {
    bottom: 0px;
}

.projects_grid li  .project_info p {
    color: #FFF;
    font-weight: 300;
    letter-spacing: 0px;
    font-size: 9px;
    position: absolute;
    bottom: 0;
    padding: 20px;
    padding-bottom: 15px;
    margin: 0;
}

.projects_grid li h3 {
    font-family: "Futura", "Nunito Sans";
    font-size: 20px;
    font-weight: 700;
    color: #FFF;
    padding: 20px;
    padding-bottom: 0px;
    margin: 0;
    float: left;
}

.project_tags {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 100%;
    height: 20px;
}

.project_tags p {
    float: right;
    top: 0;
    margin: 0;
    font-weight: 600;
    font-size: 0.5em !important;
    color: rgba(0,0,0,0.5) !important;
    background-color: #FFF;
    border: 1px solid rgba(0,0,0,0.15);
    display: inline-block;
    padding: 3px;
    padding-right: 5px;
    padding-left: 4px;
    margin: 5px;
    margin-left: 0px;
    border-radius: 3px;
    letter-spacing: -0px !important;
    position: relative;
}

.project_focused_wrap {
    position: fixed;
    top: 0;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-color: rgba(0,0,0,0.75);
    overflow: scroll;
}

.project_focused {
    position: relative;
    top: 50px;
    background-color: #FFF;
    width: 100%;
    max-width: 800px;
    margin-bottom: 50px;
    border-radius: 10px;
}

.project_focused_bg {
    position: absolute;
    margin: 0;
    height: 100%;
    width: 100%;
}


.project_focused .top_info {
    width: 100%;
    height: 70px;
}
.project_focused .top_info .info {
    float: left;
    padding-left: 1em;
    padding-right: 1em;
}

.project_focused .top_info .info h3 {
    margin-bottom: 0;
    margin-top: 15px;
}

.project_focused .top_info .info p {
    font-size: 0.8em;
    letter-spacing: 0px;
    color: #555;
    margin-top: 0;
    padding: 0;
}

.project_focused .top_info .close_btn {
    float: right;
}

.project_focused .top_info .close_btn button {
    padding: 1.5em;
    padding-right: 1em;
    padding-bottom: 2em;
    padding-top: 2em;
    background: none;
    border: none;
    margin-top: 10px;
    cursor: pointer;
    outline: none;
}
.project_focused .top_info .close_btn button > div {
    position: relative;
    width: 30px;
    height: 2px;
    background-color: #000;
    transition: all 0.5s;
}

.project_focused .project_btn {
    position: absolute;
    top: 0;
    bottom: 0;
    height: 50px;
    margin: auto;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
    transition: all 0.25s;
    
}

.project_focused .project_btn:hover {
    transform: scale(1.25);
}

.project_focused .project_btn:focus {
    outline: none;
}

.project_focused .project_btn.previous svg {
    transform: rotate(180deg);
}
.project_focused .project_btn.previous {
    left: 10px;
}
.project_focused .project_btn.next {
    right: 10px;
}

.project_focused .top_info .close_btn button > div:nth-child(1) {
    bottom: 2px;
    transform: rotate(45deg) translateY(5px);
}

.project_focused .top_info .close_btn button > div:nth-child(2) {
    top: 3px;
    left: 0;
    transform: rotate(-45deg) translateY(-5px);
}


.project_focused .image_wrap {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.project_focused .project_description p {
    margin: 1em;
    margin-top: 10px;
    font-size: 1em;
    letter-spacing: 0px;
    font-weight: 300;
}

.project_focused .tags_colours {
    margin: 1em;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-top: 0px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(0,0,0,0.07);
}

.project_focused .tags_colours .colours {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.project_focused .tags_colours .colours > div {
    border: 1px solid #CCC;
    padding: 10px;
    margin-left: 5px;
    text-align: center;
    border-radius: 4px;
}

.project_focused .tags {
    float: left;
}
.project_focused .colours {
    float: right;
    
}

.project_focused .tags_colours p {
    font-size: 0.75em;
    color: #666;
    display: inline-block;
    margin: 5px;
    margin-left: 0px;
    border-radius: 4px;
    letter-spacing: -1px !important;
    position: relative;
    text-transform: uppercase;
}
.project_focused .tags_colours svg {
    fill: #666;
    height: 13px;
    width: 13px;
    vertical-align: middle;
}

.project_focused .tags svg {
    margin-right: 5px; 
}
.project_focused .date svg {
    margin-right: 5px; 
}

.project_focused .colours svg {
    margin-left: 5px;
}

.project_focused .share {
    margin: 1em;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 0px;
    padding-bottom: 15px;
}

.project_focused .share a {
    text-decoration: none;
    font-size: 0.75em;
    color: #666;
    font-weight: 600;
    border: 1px solid #CCC;
    padding: 5px;
    width: 30%;
    text-align: center;
    border-radius: 5px;
}

.project_focused .share svg {
    height: 13px;
    width: 13px;
    vertical-align: middle;
    margin-top: -3px;
    margin-right: 5px;
}
.project_focused .share .pinterest {
    fill: #bd081c;
}
.project_focused .share .twitter {
    fill: #1da1f2;
}
.project_focused .share .copy {
    fill: #3a8bbb;
}

@media (max-width: 950px) {
    .project_focused {
        max-width: 100%;
        top: 0px;
        margin-bottom: 0px;
    }
}

@media (max-width: 750px) {
    .project_focused {
        border-radius: 0px;
    }
    .project_image_large_loading {
        height: 430px;
    }
    .projects .width-constraint h1 {
        font-size: 4.5em;
        padding-bottom: 25px;
    }
    .projects .width-constraint {
        padding-top: 100px;
    }
}