nav {
    position: fixed;
    z-index: 112;
    width: 100%;
    display: flex;
    justify-content: center;
    
}
nav ul {
   position: relative;
    padding: 0;
    margin: 0;
    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0px;
    margin-top: 20px;
    transition-duration: 0.5s;
}

#logo {
   position: relative;
   top: 2px;
   fill: #1E212F;
   transition-delay: 0.5s;
   transition-duration: 0.5s;
}
.nav.menu_open #logo {
   fill: #F4F4F4;
   transition-delay: 0.4s;
}

/* .nav #name a:focus #logo {
   fill: #3A5BFA;
   transition-delay: 0s;
} */

.nav.menu_open #name a:focus #logo {
   fill: #777777;
}

#name a {
   padding: 0.75em;
   margin-left: 15px;
   font-weight: 700;
   font-size: 1.25em;
   text-decoration: none;
   letter-spacing: -1px;
   transition-delay: 0.5s;
   transition-duration: 0.5s;
}

.nav ul li:nth-child(2) svg {
   stroke: #888A91;
   transition: stroke 0.05s 0.6s;
}
.nav ul li:nth-child(2) svg #top {
   fill: #F4F4F4;
   transition: fill 0.05s 0.6s;
}



.nav.menu_open ul li:nth-child(2) svg {
   stroke: #F4F4F4;
   transition-delay: 0.4s;
}
.nav.menu_open ul li:nth-child(2) svg #top {
   fill: #111111;
   transition: fill 0.05s 0.4s;
}


nav ul li {
   position: relative;
   z-index: 1;
   list-style: none;
   height: 50px;
   display: flex;
   justify-content: center;
   align-items: center;
   padding: 0;
   transition: all 0.75s;
}
nav ul li:nth-child(1) {
   flex-grow: 1;
   justify-content: flex-start;
   text-align: left;
}
nav ul li a:hover {
   cursor: pointer;
}

#nav_bg {
   position: fixed;
   top: 0;
   height: 80px;
   background-color: #FFF;
   opacity: 0.9;
   width: 100%;
   z-index: 10;
}

@media (min-width: 750px) {
   #nav_bg {
       height: 90px;
   }
}

nav a:focus {
   outline: none;
   color: #FFF;
}
nav a:hover {
   cursor: pointer;
}


#contact {
   height: 45px;
}

#contact .top-contact-btn {
   background: #FFF;
   height: 45px;
   width: 120px;
   display: flex;
   justify-content: center;
   align-items: center;
   color: #1E212F;
   font-weight: 600;
   text-decoration: none;
   border: 1px solid rgba(0,0,0,0.1);
   border-radius: 5px;
   font-size: 13px;
   transition-delay: 0.6s;
   transition-duration: 0.5s;
   position: relative;
}
#contact .top-contact-btn:hover {
   background: rgba(0,0,0,0.1);
   transition-delay: 0s;
}
#contact .top-contact-btn:focus { 
   border: 1px solid #3A5BFA;
   transition-delay: 0;
}

.nav.menu_open #contact .top-contact-btn  {
   opacity: 0;
   pointer-events: none;
   transition: all 0.3s 0.2s;
}

#contact .top-contact-icon {
   display: flex;
   justify-content: center;
   align-items: center;
   display: none;
   height: 30px;
   width: 30px;
   transition-delay: 0.6s;
   transition-duration: 0.5s;
}
#contact .top-contact-icon svg {
   fill: #1E212F;
   stroke-width: 0;
   height: 22px;
}

.nav.menu_open #contact .top-contact-icon  {
   opacity: 0;
   pointer-events: none;
   transition: all 0.3s 0.2s;
}

#burger {
   height: 50px;
   width: 70px;
   padding-right: 5px;
   flex-direction: column;
}

#burger:hover {
   cursor: pointer;
}

#burger > div {
   position: relative;
   width: 25px;
   height: 2px;
   background-color: #1E212F;
   transition: transform 0.25s 0.6s, background-color 0.25s 0.6s, width 0.25s 0.6s;
}

#burger > div:nth-child(1) { bottom: 5px; }
#burger > div:nth-child(2) { top: 0px; left: 0; }
#burger > div:nth-child(3) { top: 5px; left: 0; }

.nav.menu_open #burger > div {
   background-color: #F4F4F4;
   transition: all 0.25s 0.4s;
}

.nav #burger:focus {
   border: none;
   outline: none;
}


.nav.menu_open #burger > div:nth-child(1) {
   transform: rotate(45deg) translateX(4px) translateY(5px);
}
.nav.menu_open #burger > div:nth-child(2) {
   width: 0px;
}

.nav.menu_open #burger > div:nth-child(3) {
   transform: rotate(-45deg) translateX(4px) translateY(-6px);
}

@media (max-width: 750px) {
   nav ul { 
      margin-top: 10px;
      padding-top: 5px;
      padding-bottom: 5px;
   }
   #name a {
      margin-left: 5px;
      position: relative;
      top: 4px;
   }
   #contact .top-contact-btn {
      display: none;
   }
   #contact .top-contact-icon {
      display: flex;
   }
   #nav_bg {
      height: 50px;
   }
   nav ul {
      margin-top: 0;
      padding-top: 0;
    }
}

@media (min-width: 1200px) {
   nav ul {
     max-width: 85%;
   }
   nav ul li:nth-child(1) {
      right: 25px;
   }
   nav ul li:nth-child(3) {
      left: 25px;
  }
}
