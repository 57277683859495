.home {
    height: 100%;
    width: 100%;
    background-color: #FFF;
    color: #1E212F;
    position: relative;
    cursor: default;
}

.home .wipe, .home .wipe2 {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    transition: all 0.75s;
    animation-timing-function: cubic-bezier(0,0,1,1);
}

.home .wipe.hidden, .home .wipe2.hidden {
    height: 0;
    transition: all 0.5s;
}

.home .wipe {
    z-index: 201;
    background: #000;
}
.home .wipe.hidden {
}
.home .wipe2 {
    z-index: 200;
    background: #1E212F;
    transition-delay: 0.25s;
}
.home .wipe2.hidden {
    transition-delay: 0.25s;
}

.home .component-wrap {
    height: 100%;
    width: 100%;
}
.home .width-constraint {
    transition: all 0.5s;
}

.home header {
    text-align: center;
    position: relative;
    bottom: 50px;
}
.home .headline {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 100%;
}
.home .headline h3 {
    position: relative;
    left: 0;
    margin: 0;
    font-weight: 600;
    transition-duration: 0.5s;
    transition-delay: 0.5s;
}
.home .headline h1  {
    position: relative;
    opacity: 1;
    left: 0;
    font-size: 2.5em;
    font-weight: 900;
    letter-spacing: -0.05em;
    transition-duration: 0.5s;
    transition-delay: 0.5s;
}

.home .headline p {
    position: relative;
    opacity: 1;
    left: 0;
    font-size: 1em;
    line-height: 0.6em;
    transition-duration: 0.5s;
    transition-delay: 0.5s;
}


.home .headline.hidden h1, .home .headline.hidden p { 
    left: -100px;
    transition-duration: 0.5s;
    transition-delay: 0s;
    opacity: 0;
}


.text_bottom {
    position: relative;
    bottom: 20px;
}

span.superscript > div {
    font-size: 0.25em;
    font-weight: 400;
    letter-spacing: 0px;
    transform: translateX(-50px) translateY(1em);
}
span.superscript > span {
    transform: translateX(-35px)
}
.home .regular_text {
    font-size: 2em;
    line-height: 1.5em;
    font-weight: 600;
    text-align: center;
}

.inline_link {
    display: inline-block;
    color: #333;
}
.regular_text a:focus {
    outline: none;
    color: #000;
}

@keyframes scrollText {
    0% {opacity: 1;}
    30% {opacity: 0;}
    70% {opacity: 0;}
    100% {opacity: 1;}
}
@keyframes scrollImage {
    0% {
        opacity: 1;
        transform: translateY(0px);
     }
    50% {
          opacity: 0;
        transform: translateY(-200px);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}
@keyframes scrollImageUp {
    0% {
        opacity: 0;
        transform: translateY(0px);
     }
    50% {
          opacity: 0;
        transform: translateY(-200px);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

.home .headline {
    
}
.home .headline .text {
    
}

.home .headline .image {
    height: 500px;
    width: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: -1;
    position: relative;
}

.home .headline img {
    position: absolute;
    height: 100%;
    transition: all 0.75s;
    opacity: 1;
    transition-delay: 0.5s;
    z-index: -1;
}

.home .headline .image.hidden {
    opacity: 0;
    transform: scale(0.75);
    transition: all 0.5s;
    transition-delay: 0.5s;
}

.home .headline .loader {
    height: 150px;
    transition: all 0.5s;
}

.home .headline  {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    opacity: 1;
    transition: opacity 0.5s, position 1s;
    transition-delay: opacity 0.5s, position 1s;
}


.home .headline .loader.hidden {
    opacity: 0;
    position: absolute;
    transform: scale(0.75);
}

.home .headline.hidden .image {
    right: -200px;
    opacity: 0;
    transition-delay: 0s;
}

.image.scroll {
    animation: scrollText 1.5s ease-in-out;
}

@media (max-width: 900px) {
    .home .headline {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }

    .home .headline .image {
        height: 400px;
        width: 400px;
        position: relative;
        bottom: 20px;
    }

    .home .headline .text {
        position: relative;
        bottom: 30px;
    }
    
    .home .headline h1, .home .headline p {
        text-align: center;
        z-index: 1;
    }
}

@media (max-width: 750px) {
    
    .headline .regular_text > div {
        margin-bottom: 20px;
    }
    .headline .regular_text > div.no_margin {
        margin-bottom: 0px;
    }
    .home .regular_text {
        font-size: 1.5em;
        line-height: 1.5em;
    }
    
    .home .headline .image {
        bottom: 40px;
    }
    .home .headline .loading-placeholder img {
        padding-bottom: 150px;

    }
}

@media (max-width: 500px) {
    .home .headline .image {
        height: 350px;
        width: 350px;
        bottom: 25px;
    }
    .home .headline h1 {
        font-size: 2.25em;
    }
    .home .regular_text {
        font-size: 1.25em;
        line-height: 2em;
    }
}


@media (min-width: 1200px) {
    .home .headline .image {
        height: 600px;
        width: 600px;
    }
    .home .headline .text h1 {
        font-size: 3em;
        font-weight: 800;
    }
    .home .headline .text p {
        font-size: 1.2em;
    }
}
