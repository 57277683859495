.resume {
    
    background-color: #FFF;
    color: #333;
    position: relative;
    cursor: default;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.resume .document {
    padding-top: 150px;
    
}

.resume .document .page {
    padding-bottom: 0;
    height: 500px;
}
.resume button {
    font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    position: relative;
    height: 45px;
    width: 160px;
    margin: 1.5em;
    margin-top: 50px;
    margin-bottom: 100px;
    padding: 0;
    border: none;
    color: #FFF;
    font-size: 13px;
    font-weight: 600;
    border-radius: 5px;
    background: #3A5BFA;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.resume button .text {
    width: 115px;
}

.resume button .icon {
    background: #2D4EE9;
    height: 45px;
    width: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}