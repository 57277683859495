.bottom_info {
    height: 80px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 50px;
    transition-duration: 0.5s;
    transition-delay: 0.35s;
    opacity: 1;
    z-index: 2;
}
.bottom_info.contact {
    height: 80px;
    position: absolute;
    bottom: 50px;
    transition-duration: 0.5s;
    transition-delay: 0.35s;
    opacity: 1;
}

.bottom_info.hidden {
    bottom: -150px;
    transition-duration: 0.5s;
    transition-delay: 0.5s;
    /* display: block !important; */
    opacity: 0;
    height: 0;
}

.bottom_info table {
    width: 100%;
    height: 100%;
    display: table;
    table-layout: fixed;
    border-collapse: collapse;
}
.bottom_info table td {
    text-align: center;
    font-size: 0.8em;
}
.bottom_info table td button {
    border: none;
    background: none;
    font-size: 1em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-left: 20px;
    position: relative;
    width: 100%;
}

.bottom_info {
    color: #1E212F;
}
.bottom_info button {
    color: #1E212F;
    font-weight: 600;
}

.bottom_info button:focus {
    font-weight: 600;
    outline: none;
    color: #1E212F;
}

.bottom_info a:focus {
    font-weight: 600;
    outline: none;
    color: #1E212F;
}

.bottom_info table p {
    color: #1E212F;
    width: 100%;
    margin: 0;
    display: block;
    font-size: 1em;
}

.bottom_info table p span {
    color: #1E212F;
    
}
.bottom_info table a {
    color: #1E212F;
    font-weight: 600;
    text-decoration: none;
    margin: 0;
    display: block;
}

.bottom_info table td:nth-last-child(1) button {
    right: 5px;
}

.scroll_section {
    height: 2px;
    opacity: 0;
    width: 0;
    background-color: rgba(0, 0, 0, 0.1);
    margin-bottom: 2px;
    position: relative;
    animation: scrollSectionReveal 1000ms ease-out 350ms forwards;
}
@-webkit-keyframes scrollSectionReveal {
    0% { width: 0; opacity: 0; }
    10% { opacity: 1; }
    80%  {width: 100%;}
    100% { width: 100%;  opacity: 1; }
  }
  
@keyframes scrollSectionReveal {
    0% { width: 0; opacity: 0; }
    10% { opacity: 1; }
    80%  {width: 100%;}
    100% { width: 100%;  opacity: 1; }
}
  
.scroll_bar {
    position: relative;
    height: 2px;
    width: 0;
    transition: all 0.5s;
    background-color: #2D4EE9;
}
  
@media (max-width: 850px) {
    .bottom_info {
        margin-left: 10px;
        margin-right: 10px;
        bottom: 0px;
    }
}
@media (min-width: 1200px) {
    .bottom_info {
        max-width: 85vw;
    }
}   

